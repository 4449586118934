import React from "react"
import { Link } from "gatsby"
const text = (
  <span>
    <div />
    <strong id="Cookies-en-Beex"> 1. Cookies en Beex </strong>
    <br /> <br />
    En Beex se recopila información a través del uso de una tecnología
    llamada “cookies” para que tu experiencia en la web sea óptima. A
    continuación, te explicamos la forma cómo las “cookies” funcionan en nuestra
    web.
    <br /> <br />
    <strong id="Qué-es-una-cookie"> 2. ¿Qué es una cookie? </strong>
    <br /> <br />
    Cuando te conectas a Internet, utilizas un programa llamado “navegador”
    (como Safari de Apple o Chrome de Google). La mayoría de los sitios web
    almacenan una pequeña cantidad de texto en el navegador, y a ese fragmento
    de texto se le denomina “cookie”.
    <br /> <br />
    Su utilidad consiste en que la web pueda recordar tu visita cuando vuelvas a
    navegar por esa página, lo que puede facilitar tu próxima visita y hacer que
    el sitio web te resulte más útil. Así, las cookies buscan adaptar el
    contenido de la web a tu perfil y necesidades. Sin la presencia de cookies,
    tu experiencia durante la navegación en un sitio sería frustrante y los
    servicios ofrecidos por el sitio web se verían afectados.
    <br /> <br />
    <strong id="Dónde-se-utilizan-cookies">
      {" "}
      3. ¿Dónde se utilizan cookies?{" "}
    </strong>
    <br /> <br />
    Beex utiliza cookies en nuestras páginas:{" "}
    <Link href="https://beexcc.com/"> https://beexcc.com/ </Link>,
    <Link href="https://beexcc.com/blog/">
      {" "}
      https://beexcc.com/blog/{" "}
    </Link>
    .
    <br /> <br />
    <strong id="Cuáles-son-las-cookies-que-utiliza-el-sitio-web">
      {" "}
      4. ¿Cuáles son las cookies que utiliza el sitio web de Beex y para
      qué se utilizan?{" "}
    </strong>
    <br /> <br />
    <strong>4.1. Cookies técnicas o de funcionalidad </strong>
    <br />
    En Beex contamos con cookies técnicas que son imprescindibles y
    estrictamente necesarias para el correcto funcionamiento de nuestros sitios
    web. Por ejemplo, las que sirven para el mantenimiento de la sesión, la
    gestión del tiempo de respuesta o compartir contenido con redes sociales.
    <br /> <br />
    <strong>4.2. Cookies de publicidad </strong>
    <br />
    Las cookies nos ayudan a mostrarte contenido relacionado que podría
    gustarte. También utilizamos las cookies para ayudar a los anunciantes a
    mostrarte anuncios acordes a tus intereses.
    <br /> <br />
    <strong>4.3. Cookies de preferencia o personalización </strong>
    <br />
    Estas cookies nos permiten recordar información que cambia el aspecto o el
    comportamiento de nuestro sitio web en base a los ajustes que realices, como
    el ajuste de idioma.
    <br /> <br />
    <strong>4.5. Cookies de seguridad </strong>
    <br />
    Las cookies son una de las formas en las que te evitamos riesgos de
    seguridad. Por ejemplo, las utilizamos para detectar actividades
    fraudulentas o para evitar que alguien envíe spam a las páginas web de
    Beex.
    <br /> <br />
    <strong>4.6. Cookies de identificación o autenticación </strong>
    <br />
    Este tipo de cookies sirven para recordar tus datos de inicio de sesión (por
    ejemplo, tu nombre de usuario y tu contraseña) en el momento que desees
    acceder a tu cuenta en Beex. Gracias a esta cookie no necesitarás
    iniciar sesión cada vez que accedes a nuestro sitio web.
    <br /> <br />
    <strong>4.7. Analytics </strong>
    <br />
    Utilizamos las cookies para mejorar Beex. Por ejemplo, estas cookies
    nos indican cuántas personas utilizan una función específica o cuántos
    usuarios interactúan con la página.
    <br /> <br />
    También utilizamos los servicios de terceros proveedores para hacer análisis
    estadísticos y evaluar nuestros servicios y el contenido que le
    proporcionamos. Estos terceros pueden recopilar o tener acceso a información
    de manera anonimizada y conforme a sus propias políticas de privacidad y de
    cookies.
    <br /> <br />
    <strong>4.8. Proveedores de servicio </strong>
    <br />
    A veces contratamos a proveedores externos de seguridad o utilizamos
    proveedores externos de análisis de datos para ayudarnos a comprender cómo
    utilizan las personas Beex. Al igual que nosotros, posiblemente estos
    proveedores utilicen cookies.
    <br /> <br />
    Asimismo, en Beex podemos utilizar propias y cookies de terceros. Si
    revisas las cookies de terceros alojadas en la página web de Beex,
    podrás encontrar a proveedores como Google Analytics, Google DoubleClick,
    Crazy Egg, Facebook y Hubspot.
    <br /> <br />
    Debes saber que las empresas que generan estas últimas cookies tienen sus
    propias políticas de cookies, y se encuentran obligadas a obtener tu
    consentimiento, de ser el caso, y a informarte sobre la existencia de las
    cookies que generan y administran.
    <br /> <br />
    Es decir, los servicios de terceros son ajenos al control de Beex, por
    ello te recomendamos revisar las políticas de privacidad, condiciones de
    servicio, finalidad y utilización de las cookies de dichos proveedores. Si
    deseas más información sobre cómo estas empresas las gestionan, puedes
    acudir a su propia política de privacidad o de cookies.
    <br /> <br />
    <strong id="Cómo-puedo-configurar-las-cookies-en-mi-navegador">
      {" "}
      5. ¿Cómo puedo configurar las cookies en mi navegador?{" "}
    </strong>
    <br /> <br />
    En cualquier momento puedes ejercer tu derecho de desactivación, bloqueo o
    eliminación de cookies de nuestro sitio web u otros servicios de Beex.
    Por tanto, si no estás de acuerdo con el uso de cookies expuesto en esta
    política, puedes bloquear las cookies o activar la opción de no rastreo.
    Para ello, te pedimos prestar atención a las siguientes indicaciones:
    <br /> <br />
    * Tu navegador probablemente te ofrece opciones para las cookies. Por
    ejemplo, la mayoría de los navegadores permiten bloquear las “cookies de
    terceros”, que son cookies de sitios diferentes al que estás visitando.
    Estas opciones difieren de un navegador a otro, por eso, consulta la
    configuración del navegador que usas para obtener más información.
    <br /> <br />
    * En el caso de Google, puedes dirigirte a la barra de direcciones de Chrome
    y pulsar en el ícono de candado que figura al inicio. Luego, hacer click en
    la opción “Cookies” y seleccionar la cookie que deseas bloquear o quitar.
    <br /> <br />
    * Recuerda que las cookies de terceros no las gestiona Beex. Por lo
    tanto, te recomendamos revisar las políticas de privacidad, condiciones de
    servicio, finalidad y utilización de las cookies de terceros a fin de que
    puedas obtener más información sobre la desactivación de estas cookies y
    cómo puedes gestionarlas.
    <br /> <br />
    * Por otro lado, algunos navegadores también incluyen el ajuste de
    privacidad “Do Not Track” (no rastrear), con el cual somos compatibles. Este
    ajuste es una alternativa que te permite decidir si deseas que utilicemos
    información de nuestros socios y otros servicios para personalizar nuestro
    sitio web a tu gusto.
    <br /> <br />
    * También puedes optar por borrar tus datos de navegación (incluidas las
    cookies) desde tu propio navegador. No olvides consultar las opciones e
    instrucciones que ofrece tu navegador para ello.
    <br /> <br />
    <strong id="Advertencias-sobre-la-configuración-de-cookies">
      {" "}
      6. Advertencias sobre la configuración de cookies
    </strong>
    <br /> <br />
    Debes tener en cuenta que algunas cookies ayudan a hacer única tu
    experiencia en nuestra web (cookies de personalización), así como son
    imprescindibles para el correcto funcionamiento de nuestro sitio web
    (cookies técnicas) y su desactivación o bloqueo frustrarían tu navegación en
    Beex y tu experiencia en nuestra web no sería óptima.
    <br /> <br />
    Asimismo, esta decisión podría afectar la seguridad de tus datos personales
    si llegas a desactivar o bloquear las cookies de seguridad.
    <br /> <br />
    <strong id="Consentimiento-sobre-el-uso-de-cookies">
      {" "}
      7. Consentimiento sobre el uso de cookies
    </strong>
    <br /> <br />
    Al visitar nuestro sitio web y aceptar la presente Política de Cookies,
    manifiestas estar de acuerdo con todos los aspectos expuestos en este
    documento y nos autorizas para hacer uso de las cookies anteriormente
    desarrolladas, incluyendo las cookies de publicidad de terceros, de acuerdo
    con las finalidades indicadas, a menos que decidas configurarlas, en cuyo
    caso se entenderá revocado tu consentimiento.
    <br /> <br />
    Recuerda que las cookies de terceros no las gestiona Beex. Por lo
    tanto, te recomendamos revisar las políticas de privacidad, condiciones de
    servicio, finalidad y utilización de las cookies de terceros a fin de que
    puedas obtener más información sobre la desactivación de estas cookies y
    cómo puedes gestionarlas.
    <br /> <br />
    <strong id="Vigencia-y-actualizaciones-de-la-presente-política">
      {" "}
      8. Vigencia y actualizaciones de la presente política de cookies
    </strong>
    <br /> <br />
    Beex se reserva el derecho a modificar la presente Política en función
    de exigencias legislativas, reglamentarias, criterios propios o con la
    finalidad de adaptar dicha política a las instrucciones o criterios dictados
    por la Autoridad Nacional de Protección de Datos Personales peruana.
    <br /> <br />
    Si se introdujera algún cambio en esta Política, el nuevo texto se publicará
    en nuestro sitio web{" "}
    <Link href="https://beexcc.com/">https://beexcc.com/ </Link>.
  </span>
)

const data = {
  description: {
    title: "Política de Cookies",
    textbody: text,
    intro: "Introducción",
    class: "container-menu-cookies ",

    menu: [
      {
        text: "Cookies en Beex",
        href: "#Cookies-en-Beex",
      },
      {
        text: "¿Qué es una cookie?",
        href: "#Qué-es-una-cookie",
      },
      {
        text: "¿Dónde se utilizan cookies?",
        href: "#Dónde-se-utilizan-cookies",
      },

      {
        text:
          "¿Cuáles son las cookies que utiliza el sitio web de Beex y para qué se utilizan?",
        href: "#Cuáles-son-las-cookies-que-utiliza-el-sitio-web",
      },

      {
        text: "¿Cómo puedo configurar las cookies en mi navegador?",
        href: "#Cómo-puedo-configurar-las-cookies-en-mi-navegador",
      },
      {
        text: "Advertencias sobre la configuración de cookies",
        href: "#Advertencias-sobre-la-configuración-de-cookies",
      },
      {
        text: "Consentimiento sobre el uso de cookies",
        href: "#Consentimiento-sobre-el-uso-de-cookies",
      },
      {
        text: "Vigencia y actualizaciones de la presente política de cookies",
        href: "#Vigencia-y-actualizaciones-de-la-presente-política",
      },
    ],
  },
}

export default data
